
import { defineComponent, ref } from "vue";
import * as LandingServices from "../api/helpers/Landing";
import Spinner from "../components/Spinner.vue";
export default defineComponent({
  name: "Results",
  components: {
    Spinner,
  },
  setup() {
    var loading = ref(false);
    var html = ref();
    const LANDING_PATH = "/landing/";
    const landingId = window.location.pathname.replace(LANDING_PATH, "");
    return {
      loading,
      landingId,
      html,
    };
  },
  mounted() {
    this.loading = true;
    document.title = "Prestamos Personales | " + process.env.VUE_APP_TITLE;
    window.scrollTo(0, 0);
    const PARAMS = "params";
    if (!this.landingId) this.$router.push("../");
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      var params: any;
      for (const entry of urlParams.entries()) {
        params[entry[0]] = entry[1];
      }
      sessionStorage.setItem(PARAMS, JSON.stringify(params));
    }
    this.find();
  },
  methods: {
    find() {
      LandingServices.find(this.landingId, process.env.VUE_APP_SYSTEM_ID).then(
        (res) => {
          const { data } = res.data;
          if (!data.length) this.$router.push("../");
          this.html = data[0].html;
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.$router.push("../");
        }
      );
    },
  },
});
